<template>
  <div class="wrap">
    <div class="banner_img">
      <img class="img" :src="detail.pcActivityImage ? detail.pcActivityImage : ''" alt="" />
    </div>
    <div class="main_box">
      <!-- 优惠券 -->
      <div class="coupon_box">
        <div class="title_box">
          <div class="title">
            {{ detail.discountCouponName ? detail.discountCouponName : "" }}
          </div>
          <div class="tag">每位学员限领一次</div>
        </div>
        <div class="coupon">
          <div class="coupon_wrap">
            <div class="coupon_item" v-for="(item, index) in detail.discountCoupons" :key="index">
              <div class="top_box"></div>
              <div class="bottom_box">立即领取</div>
            </div>
          </div>
          <div class="btn">
            <span class="m-l-10">一键</span>
            <span class="m-l-10">领取</span>
          </div>
        </div>
      </div>
      <!-- 热门课程 -->
      <div class="course_box">
        <div class="title_box">{{ activityList[1].name }}</div>
        <div class="course_content">
          <div class="content_wrap">
            <div class="course_item" v-for="(item, index) in detail.courses" :key="index">
              <img class="img" :src="item.img" alt="" />
              <div class="text_box">
                <div class="course_title twoEllipsis">
                  {{ item.title }}
                </div>
                <div class="price_box">
                  <div class="price">
                    <span class="symbol">￥</span>{{ item.price }}
                  </div>
                  <div class="btn">
                    <span class="m-l-5">立即</span>
                    <span class="m-l-5">学习</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="more_activity_box">
        <div v-if="activityList.length > 2" class="left_box">
          <img class="img" src="@/assets/img/activityThemePage/redEnvelopes.png" alt="" />
          <div class="main_box">
            <div class="coupon">优惠券</div>
            <div class="main_item" :class="navInfo == index ? 'on' : ''" v-for="(item, index) in activityList"
              :key="index">
              {{ item.name }}
            </div>
            <div class="to_top">
              <img src="@/assets/img/activityThemePage/top.png" alt="" />
            </div>
          </div>
        </div>
        <!-- 拼团活动课程 -->
        <div class="right_box">
          <div v-for="(item, index) in activityList" :key="index">
            <div v-if="index > 1" class="title_box">{{ item.name }}</div>
            <div v-if="index > 1" class="group_activity">
              <div class="group_wrap">
                <div class="course_item m-b-30" v-for="(item, index) in item.list" :key="index">
                  <img class="img" :src="item.entrantsProductDtoVo.img" alt="" />
                  <div class="text_box">
                    <div class="course_title twoEllipsis">
                      {{
                        item.entrantsProductDtoVo.name
                      }}
                    </div>
                    <div class="price_box">
                      <div class="price">
                        <span class="symbol">￥</span>{{ item.entrantsProductDtoVo.entrantsPrice }}
                      </div>
                      <div class="btn">
                        <span class="m-l-5">立即</span>
                        <span class="m-l-5">学习</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="title_box">助力活动课程</div>
          <div class="group_activity">
            <div class="group_wrap">
              <div
                class="course_item m-b-30"
                v-for="(item, index) in 6"
                :key="index"
              >
                <img
                  class="img"
                  src="https://staticfile.xlcwx.com/userfiles/202208/998c67b4254949468781954719a74039c20220819173801914.jpg"
                  alt=""
                />
                <div class="text_box">
                  <div class="course_title twoEllipsis">
                    {{
                      "一节课突破考试壁垒，考上好大大大大大大大大大大大大大大大"
                    }}
                  </div>
                  <div class="price_box">
                    <div class="price">
                      <span class="symbol">￥</span>{{ 3599 }}
                    </div>
                    <div class="btn">
                      <span class="m-l-5">立即</span>
                      <span class="m-l-5">学习</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="title_box">限时折扣课程</div>
          <div class="group_activity">
            <div class="group_wrap">
              <div
                class="course_item m-b-30"
                v-for="(item, index) in 6"
                :key="index"
              >
                <img
                  class="img"
                  src="https://staticfile.xlcwx.com/userfiles/202208/998c67b4254949468781954719a74039c20220819173801914.jpg"
                  alt=""
                />
                <div class="text_box">
                  <div class="course_title twoEllipsis">
                    {{
                      "一节课突破考试壁垒，考上好大大大大大大大大大大大大大大大"
                    }}
                  </div>
                  <div class="price_box">
                    <div class="price">
                      <span class="symbol">￥</span>{{ 3599 }}
                    </div>
                    <div class="btn">
                      <span class="m-l-5">立即</span>
                      <span class="m-l-5">学习</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="title_box">打卡活动课程</div>
          <div class="group_activity">
            <div class="group_wrap">
              <div
                class="course_item m-b-30"
                v-for="(item, index) in 6"
                :key="index"
              >
                <img
                  class="img"
                  src="https://staticfile.xlcwx.com/userfiles/202208/998c67b4254949468781954719a74039c20220819173801914.jpg"
                  alt=""
                />
                <div class="text_box">
                  <div class="course_title twoEllipsis">
                    {{
                      "一节课突破考试壁垒，考上好大大大大大大大大大大大大大大大"
                    }}
                  </div>
                  <div class="price_box">
                    <div class="price">
                      <span class="symbol">￥</span>{{ 3599 }}
                    </div>
                    <div class="btn">
                      <span class="m-l-5">立即</span>
                      <span class="m-l-5">学习</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="title_box">线下活动课程</div>
          <div class="group_activity">
            <div class="group_wrap">
              <div
                class="course_item m-b-30"
                v-for="(item, index) in 6"
                :key="index"
              >
                <img
                  class="img"
                  src="https://staticfile.xlcwx.com/userfiles/202208/998c67b4254949468781954719a74039c20220819173801914.jpg"
                  alt=""
                />
                <div class="text_box">
                  <div class="course_title twoEllipsis">
                    {{
                      "一节课突破考试壁垒，考上好大大大大大大大大大大大大大大大"
                    }}
                  </div>
                  <div class="price_box">
                    <div class="price">
                      <span class="symbol">￥</span>{{ 3599 }}
                    </div>
                    <div class="btn">
                      <span class="m-l-5">立即</span>
                      <span class="m-l-5">学习</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMarketOnlineActivity } from "@/api/marketing.js";
export default {
  data() {
    return {
      moreActivityBox: null,
      leftBox: null,
      id: null,
      detail: {},
      navInfo: 0,
      activityList: [],
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getDetail();
  },
  mounted() {
    window.addEventListener("scroll", this.scrollToTop);
  },
  beforeRouteLeave(to, form, next) {
    // 离开路由移除滚动事件
    window.removeEventListener("scroll", this.scrollToTop);
    next();
  },
  methods: {
    getDetail() {
      let data = {
        id: this.id,
      };
      getMarketOnlineActivity(data).then((res) => {

        let data = res.data;
        let arr = [];
        // 优惠券
        if (data.discountCoupons && data.discountCoupons.length > 0) {
          arr.push({
            name: data.discountCouponName,
            list: data.discountCoupons,
          });
        }
        // 精品课程
        if (data.courses && data.courses.length > 0) {
          arr.push({
            name: data.courseName,
            list: data.courses,
          });
        }
        // 拼团活动
        if (data.groupWorks && data.groupWorks.length > 0) {
          arr.push({
            name: data.groupWorkName,
            list: data.groupWorks,
          });
        }
        // 助力活动
        if (data.helps && data.helps.length > 0) {
          arr.push({
            name: data.helpName,
            list: data.helps,
          });
        }
        // 砍价活动
        if (data.bargains && data.bargains.length > 0) {
          arr.push({
            name: data.bargainName,
            list: data.bargains,
          });
        }
        // 限时折扣
        if (data.limitedDiscounts && data.limitedDiscounts.length > 0) {
          arr.push({
            name: data.limitedDiscountName,
            list: data.limitedDiscounts,
          });
        }
        // 大转盘
        if (data.luckyTurntables && data.luckyTurntables.length > 0) {
          arr.push({
            name: data.luckyName,
            list: data.luckyTurntables,
          });
        }
        // 打卡
        if (data.marketClocks && data.marketClocks.length > 0) {
          arr.push({
            name: data.clockName,
            list: data.marketClocks,
          });
        }

      

        this.activityList = arr;
        this.detail = res.data;

        this.$nextTick(() => {
          this.moreActivityBox = document.querySelector(".more_activity_box");
          this.leftBox = document.querySelector(".left_box");
         
        });
      });
    },
    scrollToTop() {
      let top = this.moreActivityBox.getBoundingClientRect().top;
      let bottom = this.moreActivityBox.getBoundingClientRect().bottom;
      if (!top || !bottom || !this.leftBox) return;

      if (top < 0 && bottom > 826) {
        this.leftBox.style.position = "fixed";
        this.leftBox.style.top = "100px";
      } else if (top < 0 && bottom < 826) {
        this.leftBox.style.position = "absolute";
        this.leftBox.style.top = "";
        this.leftBox.style.bottom = "0";
      } else {
        this.leftBox.style.position = "absolute";
        this.leftBox.style.top = "100px";
      }

      let titlebox = document.querySelectorAll(".title_box");
      let arr = [];
      titlebox.forEach((item, index) => {
        if (item.getBoundingClientRect().top < 1) {
          arr.push({
            top: item.getBoundingClientRect().top,
            index,
          });
        }
      });

      let arr1 = arr.sort((a, b) => {

        return b.top - a.top;
      });
      if (arr1.length > 0) {
        this.navInfo = arr1[0].index;
      }
    
    },
  },
};
</script>

<style lang="less" scoped>
.wrap {
  background-color: #a4302d;
  //   min-height: 68vh;
  padding-bottom: 100px;

  .banner_img {
    height: 474px;

    .img {
      height: 100%;
      margin: auto;
    }
  }

  .main_box {
    width: 1200px;
    margin: auto;

    .coupon_box {
      width: 997px;
      height: 348px;
      margin: auto;
      padding-top: 31px;
      padding-left: 31px;
      margin-bottom: 86px;
      margin-top: -36px;
      background-image: url("~@/assets/img/activityThemePage/couponImg.png");
      position: relative;
      z-index: 1;

      .title_box {
        display: flex;
        align-items: center;
        margin-bottom: 38px;

        .title {
          color: #6c3a00;
          font-size: 42px;
          font-weight: bold;
          margin-right: 24px;
        }

        .tag {
          border: 1px #845b2b solid;
          width: 153px;
          height: 33px;
          border-radius: 22px;
          text-align: center;
          line-height: 33px;
          color: #845b2b;
        }
      }

      .coupon {
        display: flex;
        align-items: center;

        // justify-content: ;
        .coupon_wrap {
          width: 752px;
          display: flex;
          align-items: center;

          .coupon_item {
            width: 164px;
            height: 192px;
            border-radius: 17.5px;
            margin-right: 24px;
            flex-shrink: 0;
            background-image: url("~@/assets/img/activityThemePage/couponCover.png");

            .top_box {
              height: 149px;
            }

            .bottom_box {
              height: 43px;
              color: #f7454f;
              font-size: 21px;
              text-align: center;
            }
          }
        }

        .btn {
          width: 164px;
          height: 146px;
          border-radius: 16px;
          background: linear-gradient(360deg, #f7454f 0%, #ffa981 100%);
          font-size: 46px;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          letter-spacing: 10px;
          cursor: pointer;

          .m-l-10 {
            margin-left: 10px;
          }
        }
      }
    }

    .course_box {
      margin-bottom: 55px;

      .title_box {
        width: 470px;
        height: 158px;
        margin: auto;
        color: #a7242b;
        font-size: 42px;
        font-weight: bold;
        line-height: 176px;
        text-align: center;
        font-weight: bold;
        margin-bottom: 30px;
        background-image: url("~@/assets/img/activityThemePage/titleImg.png");
      }

      .course_content {
        height: 1027px;
        border-radius: 18px;
        background: linear-gradient(180deg, #d44b38 0%, #cf3941 100%);
        box-shadow: 4px 2px 40px 1px rgba(0, 0, 0, 0.23);
        padding: 13px;

        .content_wrap {
          width: 1174px;
          height: 993px;
          background: linear-gradient(180deg, #fef0c0 0%, #ffbb6e 100%);
          border-radius: 18px;
          padding-top: 34px;
          padding-left: 29px;
          display: flex;
          flex-wrap: wrap;
        }
      }
    }

    .more_activity_box {
      display: flex;
      position: relative;

      .left_box {
        width: 219px;
        max-height: 749px;
        position: absolute;
        margin-top: 60px;

        // top: 100px;
        // bottom: 0;
        .img {
          position: absolute;
          top: -147px;
        }

        .main_box {
          .coupon {
            height: 111px;
            width: 219px;
            font-size: 40px;
            color: #ffffff;
            font-weight: bold;
            line-height: 127px;
            text-align: center;
            background-image: url("~@/assets/img/activityThemePage/backImg.png");
          }

          .main_item {
            height: 77px;
            width: 219px;
            background-color: #ffffff;
            border-bottom: 1px solid #d29498;
            box-sizing: border-box;
            font-size: 26px;
            color: #88191b;
            text-align: center;
            line-height: 77px;
            cursor: pointer;
          }

          .on {
            background-color: #ffc37b;
            color: #88191b;
            border: none;
          }

          .to_top {
            width: 219px;
            height: 76px;
            background-color: #d84b4b;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
        }
      }

      .right_box {
        width: 834px;
        margin-left: 326px;

        .title_box {
          width: 470px;
          height: 176px;
          margin: auto;
          margin-bottom: 30px;
          background-repeat: no-repeat;
          color: #a7242b;
          font-size: 42px;
          font-weight: bold;
          text-align: center;
          line-height: 176px;
          background-image: url("~@/assets/img/activityThemePage/titleImg.png");
        }

        .group_activity {
          width: 834px;
          //   height: 1507px;
          background: linear-gradient(180deg, #d44b38 0%, #cf3941 100%);
          box-shadow: 4px 2px 40px 1px rgba(0, 0, 0, 0.23);
          border-radius: 18px;
          padding: 15px 11px;
          margin-bottom: 69px;

          .group_wrap {
            // height: 1477px;
            background: linear-gradient(180deg, #fef0c0 0%, #ffbb6e 100%);
            border-radius: 18px;
            padding-top: 34px;
            padding-left: 38px;
            display: flex;
            flex-wrap: wrap;
          }
        }
      }
    }
  }
}

.course_item {
  width: 350px;
  height: 442px;
  border-radius: 17px;
  margin-right: 28px;
  padding: 11px;
  background-color: #ffffff;

  .img {
    width: 328px;
    height: 242px;
    border-radius: 10px;
    margin-bottom: 20px;
  }

  .text_box {
    .course_title {
      color: #373a42;
      font-size: 26px;
      font-weight: bold;
      line-height: 35px;
      margin-bottom: 9px;
      height: 70px;
    }

    .price_box {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .price {
        color: #de252f;
        font-size: 36px;
        font-weight: bold;

        .symbol {
          font-size: 26px;
        }
      }

      .btn {
        width: 90px;
        height: 79px;
        border-radius: 6px;
        background: linear-gradient(360deg, #d34a56 0%, #f74c45 100%);
        color: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 26px;
        font-weight: bold;
        letter-spacing: 5px;
        cursor: pointer;

        .m-l-5 {
          margin-left: 5px;
        }
      }
    }
  }
}

.m-b-30 {
  margin-bottom: 30px;
}
</style>
